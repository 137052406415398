import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {hasData} from "../utils";
import colors from "../constants/colors";
// import background from '../images/wings.jpg';
import background from '../images/zonaanbidster.png';

const CONTAINER = styled.div`
  position: fixed;
  background-color: ${(props) => props.backgroundColor};
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  transform: scaleX(-1);
  opacity: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;


function StyleBody(props) {

  let backgroundColor = colors.white;

  if (props.color === 'red') {
    backgroundColor = colors.salmon;
  }

  if (props.color === 'green') {
    backgroundColor = colors.tea;
  }

  if (props.color === 'blue') {
    backgroundColor = colors.softBlue;
  }

  if (props.color === 'purple') {
    backgroundColor = colors.lighterPurple;
  }

  let borderColor = backgroundColor;

  if (hasData(props.hollow)) {
    borderColor = backgroundColor;
    backgroundColor = 'transparent';

    if (!hasData(props.color)) {
      borderColor = colors.brownGrey;
    }
  }

  return (
    <CONTAINER
      {...props}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      {props.children}
    </CONTAINER>
  );
}

StyleBody.propTypes = {
  color: PropTypes.string,
};

export default StyleBody;
