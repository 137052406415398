import React from 'react'
import {Body, Container, H1, H2, P} from "./components";

function App() {

  return <div style={{ padding: '70px 40px'}}>
      <Container color={'white'} style={{opacity: '.7'}}>
        <H1 color={'black'}>
          Marlies Heylmann
          <br />
          <small>
            Hilversum (1954)
          </small>
        </H1>
        <P color={'black'}>Marlies Heylmann volgde de lerarenopleiding textiele vormgeving in Haarlem en de lerarenopleiding tekenen in Amsterdam. Pakt vanaf 1990 haar professionele loopbaan als autonoom kunstenaar op en maakt zowel werk in het platte vlak als ruimtelijke vormen in keramiek.</P>
        <P color={'black'}>Vier jaar later wordt haar eerste beeld in brons gegoten. Vanaf 1996, na haar verhuizing naar de provincie Groningen legt zij zich volledig toe op het beeldhouwen.</P>
      </Container>
      <Container color={'white'} style={{opacity: '.7'}}>
        <H2 color={'black'}>
          "Stukje uit mijn hart"
        </H2>
        <P color={'black'} style={{ fontStyle: 'italic'}}>
          Wat is mooi? Geen idee. Weet wel wat ik mooi vind. Soms vind ik iets tijdelijk mooi. Dat heb ik zowel met muziek als met dingen aan mijn muur. Dat zijn de grafiekjes die na de verbouwing op natuurlijke wijze niet terugkeren, zoals platen en cd's ook uit de gratie kunnen raken. Er is ook kunst die een steeds prominentere plaats in mijn huis krijgt. Een schilderij of een beeld dat vanzelf naar een steeds mooiere plek verhuisd. Dat is al heel vlug gebeurd met het beeld van Marlies. Ik kocht het, legde het op een mooie plek, maar daar zag ik het te weinig. Ik heb het al heel snel mee naar mijn werkkamer genomen. Daar zit ik namelijk het meest. Daar ligt het continu in het zicht. En niet alleen in het zicht. Ik kan het nu ook aanraken. Het is een beeld dat je, elke keer als je langsloopt, vanzelf even streelt. Niet alleen omdat het een vrouw is. Het  komt ook door het materiaal, prachtig albast. Het beeld wordt ook mooier. Hoe vaker je het ziet, hoe mooier het wordt. Wat mooi is weet ik niet. Weet alleen wat ik mooi vind en het beeld van Marlies vind ik prachtig. En eigenlijk veel meer dan dat.
        </P>
        <P color={'black'} style={{ fontStyle: 'italic'}}>
          Youp van 't Hek
        </P>
      </Container>
      <Body />
    </div>;
  }

export default App;
